import * as React from "react";
import {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {icon} from "@fortawesome/fontawesome-svg-core";
import {Link} from "gatsby";

export default ({pageContext: {allCategories}}) => {
    return (
        <div>
            <h2>
                Produktkategorien
            </h2>
            <Row>
                {allCategories.map((item: any, index: number) =>
                    <Col key={index}>
                        <Link to={`/category/${item.name}`}>
                            <img src={item.ImageLink} />
                            <p>
                                {item.title}
                            </p>
                        </Link>
                    </Col>
                    )}
            </Row>
        </div>
    )
}